import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import './graphql/hero.fragment'
import './graphql/imageTextBlock.fragment'
import './graphql/accordionTextBlock.fragment'
import './graphql/centerTextBlock.fragment'
import './graphql/localExperienceBlock.fragment'
import { Helmet } from 'react-helmet'
import ComponentContainer from './ComponentContainer'
import { mapSecondaryMenu } from '../utils'
import GatsbyPageContextProvider from '../contexts/GatsbyPageContextProvider'

function CampaignPageTemplate({
  path,
  data: { datoCmsCampaignPage, datoCmsSite },
  pageContext: { menu, footer, cookiePrompt, consumer, locale }
}) {
  const primaryButton =
    datoCmsCampaignPage.primaryButton && datoCmsCampaignPage.primaryButton.length > 0
      ? { ...datoCmsCampaignPage.primaryButton[0], type: 'button' }
      : menu.primaryButton

  return (
    <GatsbyPageContextProvider locale={locale}>
      <TailwindLayout
        menu={{
          ...menu,
          secondaryMenu: mapSecondaryMenu(datoCmsCampaignPage.secondaryMenu, path),
          dark:
            datoCmsCampaignPage.navigationStyle === 'dark' ||
            datoCmsCampaignPage.navigationStyle === 'gated'
        }}
        footer={footer}
        cookiePrompt={cookiePrompt}
        consumer={consumer}
        overrideLogo={datoCmsCampaignPage.overrideLogo?.url}
        primaryButton={primaryButton}
      >
        <Helmet>
          {datoCmsCampaignPage.stylesheet === 'contact-support' && (
            <link rel="stylesheet" type="text/css" href="/styles/contact-support.css" />
          )}
        </Helmet>
        <HelmetDatoCms
          seo={datoCmsCampaignPage.seoMetaTags || datoCmsSite.seoMetaTags}
          favicon={datoCmsSite.faviconMetaTags}
        />
        <div
          style={{
            backgroundColor:
              datoCmsCampaignPage.backgroundColor && datoCmsCampaignPage.backgroundColor.hex
          }}
        >
          <ComponentContainer locale={locale} content={datoCmsCampaignPage.content} />
        </div>
      </TailwindLayout>
    </GatsbyPageContextProvider>
  )
}

export default CampaignPageTemplate

export const query = graphql`
  query ($locale: String!, $pageId: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsCampaignPage(originalId: { eq: $pageId }, locale: { eq: $locale }) {
      title
      seoMetaTags {
        tags
      }
      navigationStyle
      hideNavigation
      stylesheet
      primaryButton {
        text
        title
        href
        openTab
      }
      overrideLogo {
        url
      }
      backgroundColor {
        hex
      }
      secondaryMenu {
        ...MenuParentItemProps
      }
      content {
        type: __typename
        ... on DatoCmsHeroBlock {
          ...HeroProps
        }
        ... on DatoCmsContentBlock {
          fragment
          topTitle
          title
          subtitle
          number
          backgroundImage {
            url
            alt
            gatsbyImageData
            height
          }
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
          content {
            type: __typename
            ... on DatoCmsLogoBlock {
              ...LogoBlockProps
            }
            ... on DatoCmsContentBlockStatsBlock {
              stats {
                title
                content
                icon {
                  url
                  alt
                  gatsbyImageData
                }
              }
              smallPaddingBottom
              backgroundColor {
                hex
              }
              textColor {
                hex
              }
              contentColor {
                hex
              }
            }
            ... on DatoCmsContentBlockTextInfoBox {
              content
              cta {
                title
                text
                href
                openTab
              }
              infoBoxes {
                title
                content
                icon {
                  url
                  alt
                  gatsbyImageData
                }
              }
            }
            ... on DatoCmsContentBlockTwoColumnInfoBox {
              infoBoxes {
                title
                content
                icon {
                  url
                  alt
                  gatsbyImageData
                }
              }
            }
            ... on DatoCmsBannerBlock {
              title
              subtitle: content
              backgroundColor {
                hex
              }
              cta {
                title
                text
                href
                openTab
              }
            }
            ... on DatoCmsCustomerStoriesBlock {
              customerStories {
                title
                image {
                  url
                  alt
                  gatsbyImageData
                }
                backgroundColor {
                  hex
                }
                textColor {
                  hex
                }
                badgeText
                badgeColor {
                  hex
                }
                badgeTextColor {
                  hex
                }
                quote
                link
                linkText
                linkOpenTab
              }
            }
            ... on DatoCmsFormContainer {
              ...FormContainerProps
            }
            ... on DatoCmsOneTabContainer {
              tabs {
                oneText
                title
                content
                ctas {
                  href
                  title
                  text
                  openTab
                }
              }
            }
            ... on DatoCmsCardContainer {
              ...CardContainerProps
            }
            ... on DatoCmsFeaturedBanner {
              highlightText
              title
              content
              ctas {
                text
                title
                href
                openTab
              }
              image {
                url
                alt
                gatsbyImageData
              }
              bannerBackgroundGradient
            }
            ... on DatoCmsContactSalesBlock {
              ...ContactSalesBlockProps
            }
            ... on DatoCmsOperatorComparison {
              firstCardTitle
              firstCardItems
              secondCardTitle
              secondCardItems
              cta {
                title
                href
                text
                openTab
              }
            }
            ... on DatoCmsOverlappingCard {
              ...OverlappingCardProps
            }
            ... on DatoCmsFeatureContainer {
              ...FeatureContainerProps
            }
            ... on DatoCmsScrollCardContainer {
              showNumbers
              darkCard
              expandText
              cta {
                title
                href
                text
                openTab
              }
              cardHeight
              showCardCtaBelowFooter
              cards {
                type: __typename
                ... on DatoCmsScrollCardContainerCard {
                  title
                  content
                  contentNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  image {
                    url
                    alt
                    gatsbyImageData
                  }
                  cta {
                    title
                    href
                    text
                    openTab
                  }
                  footer: footerNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  borderTopColor {
                    hex
                  }
                }
                ... on DatoCmsScrollCardContainerCustomerStoryCard {
                  logo {
                    url
                    alt
                    gatsbyImageData
                  }
                  contentNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  author
                  cta {
                    title
                    text
                    href
                    openTab
                  }
                }
              }
            }
            ... on DatoCmsVideoScrollContainer {
              textColor {
                hex
              }
              cta {
                title
                href
                text
                openTab
              }
              cards {
                title
                content
                thumbnail {
                  url
                  alt
                  gatsbyImageData(width: 278)
                }
                videoUrl
              }
              oneText
            }
            ... on DatoCmsItemsGroup {
              bgGrey: greyBackground
              title
              items {
                title
                content
                image {
                  url
                  alt
                  gatsbyImageData
                }
                ctaText
                ctaLink
                ctaOpenTab
              }
              itemsTitleFontStyle
            }
            ... on DatoCmsImageTextBlock {
              ...ImageTextBlockProps
            }
            ... on DatoCmsIconRightBoxContainer {
              ...IconRightBoxContainerProps
            }
            ... on DatoCmsProductsContainer {
              ...ProductsContainerProps
            }
            ... on DatoCmsFullWidthBanner {
              ...FullWidthBannerProps
            }
            ... on DatoCmsCustomerStoryTab {
              ...BannerCoreTabsProps
            }
            ... on DatoCmsExecutiveTeamContainer {
              ...ExecutiveTeamContainerProps
            }
            ... on DatoCmsOfficeAddressContainer {
              ...OfficeAddressContainerProps
            }
            ... on DatoCmsTabbedImageTextBlock {
              ...TabbedImageTextBlockProps
            }
            ... on DatoCmsAccordionTextBlock {
              ...AccordionTextBlockProps
            }
            ... on DatoCmsCenterTextBlock {
              ...CenterTextBlockProps
            }
            ... on DatoCmsTestimonialContainer {
              ...TestimonialContainerProps
            }
            ... on DatoCmsValueContainer {
              ...ValueContainerProps
            }
            ... on DatoCmsLatestVacanciesBlock {
              ...LatestVacanciesBlockProps
            }
            ... on DatoCmsVacancyDetail {
              ...VacancyDetailProps
            }
            ... on DatoCmsLocalExperienceBlock {
              ...LocalExperienceBlockProps
            }
            ... on DatoCmsLowMiddleCard {
              ...LowMiddleCardBlockProps
            }
            ... on DatoCmsTooltipBoxBlock {
              ...TooltipBoxBlockProps
            }
            ... on DatoCmsVerticalTabContainer {
              ...VerticalTabsContainerProps
            }
            ... on DatoCmsAccordion {
              ...AccordionProps
            }
            ... on DatoCmsIconsGroup {
              ...IconGroupProps
            }
            ... on DatoCmsCoverageContainer {
              ...CoverageContainerProps
            }
            ... on DatoCmsBusinessTcoCalculator {
              ...BusinessTcoCalculatorProps
            }
            ... on DatoCmsUngatedContentBlock {
              ...UngatedPageContentProps
            }
            ... on DatoCmsRelatedContent {
              ...RelatedContentProps
            }
            ... on DatoCmsAboutTruphoneBlock {
              ...AboutTruphoneProps
            }
            ... on DatoCmsOobCostsContainer {
              ...OobCostsContainerProps
            }
            ... on DatoCmsSimpleBanner {
              ...SimpleBannerProps
            }
            ... on DatoCmsFooterLinksContainer {
              ...FooterLinksProps
            }
            ... on DatoCmsHoverBoxContainer {
              ...HoverBoxContainerProps
            }
            ... on DatoCmsRichTextTab {
              ...RichTextTabProps
            }
            ... on DatoCmsSplitBackgroundBlock {
              ...SplitBackgroundBlockProps
            }
            ... on DatoCmsVideoCarousel {
              ...VideoCarouselProps
            }
            ... on DatoCmsFinanceCardContainer {
              ...FinanceCardContainerProps
            }
            ... on DatoCmsCenterQuoteBlock {
              ...CenterQuoteBlockProps
            }
            ... on DatoCmsArticleDetailBlock {
              ...ArticleDetailBlockProps
            }
            ... on DatoCmsRelatedArticleContainer {
              ...RelatedArticleContainerProps
            }
            ... on DatoCmsFinancePartnersContainer {
              ...FinancePartnersContainerProps
            }
            ... on DatoCmsToastBlock {
              ...ToastBlockProps
            }
            ... on DatoCmsGatedPageContent {
              ...GatedPageContentProps
            }
            ... on DatoCmsGatedThankYouContent {
              ...GatedThankYouContentProps
            }
            ... on DatoCmsTechnologyDataPackage {
              ...TechnologyDataPackageProps
            }
            ... on DatoCmsTechnologyList {
              ...TechnologyListProps
            }
            ... on DatoCmsImageCarouselTextBlock {
              ...ImageCarouselTextBlockProps
            }
            ... on DatoCmsTabContainer {
              ...TabContainerProps
            }
            ... on DatoCmsRevealImageBlock {
              ...RevealImageProps
            }
            ... on DatoCmsIconsColumnGroup {
              ...IconsColumnGroupProps
            }
            ... on DatoCmsCaseStudy {
              ...CaseStudyProps
            }
            ... on DatoCmsSixtySecondsChallenge {
              ...SixtySecondsChallengeProps
            }
            ... on DatoCmsTextCardBlock {
              ...TextCardBlockProps
            }
            ... on DatoCmsPricingModelBlock {
              ...PricingModelBlockProps
            }
            ... on DatoCmsImageIconGroup {
              ...ImageIconGroupProps
            }
            ... on DatoCmsPagedTabContainer {
              ...PagedTabContainerProps
            }
            ... on DatoCmsCellularModulesContainer {
              ...CellularModulesContainerProps
            }
            ... on DatoCmsIotPlansBlock {
              ...IotPlansBlockProps
            }
            ... on DatoCmsTwoItemTextBlock {
              ...TwoItemTextBlockProps
            }
            ... on DatoCmsTechnologyCaseStudiesCarousel {
              ...TechnologyCaseStudiesCarouselProps
            }
            ... on DatoCmsPrepaidPageBlock {
              ...PrepaidPageProps
            }
            ... on DatoCmsPrepaidRateCheckerBlock {
              ...PrepaidRateCheckerProps
            }
          }
        }
      }
    }
  }
`
